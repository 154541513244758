define('m10/controllers/anschlussgeometrie', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    displayErrors: true,
    rwinkelv: "0.0",
    rwinkelh: "90.0",
    schraubendisabled: true,
    schraubenanordnung: true,

    ttRwinkelV: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0 - 60 [°]";
    }),
    ttRwinkelH: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "30 - 90 [°]";
    }),
    i18n: Ember['default'].inject.service(),

    validations: {
      rwinkelv: {
        numericality: {
          greaterThanEqualTo: 0,
          lessThanOrEqualTo: 60
        }
      },
      rwinkelh: {
        numericality: {
          greaterThanOrEqualTo: 30,
          lessThanOrEqualTo: 90
        }
      }
    },

    schrauben: [
    // {
    //   name: "Schrauben senkrecht HT",
    //   ind: 0
    // },
    {
      name: "Schrauben parallel NT",
      ind: 1
    }],

    sanordnung: [{
      name: "gekreuzte Schraubenpaare",
      value: true
    }, {
      name: "geneigte Schrauben",
      value: false
    }],

    setValues: function setValues(values) {
      var self = this;

      if (self.debug) {
        console.log("values in anschlussgeometrie: ");
        console.log(values);
      }

      var rwinkelvEvent = {
        target: {
          name: "rwinkelv"
        }
      };
      var rwinkelhEvent = {
        target: {
          name: "rwinkelh"
        }
      };

      this.set('openFromFile', true);

      this.set('rwinkelv', parseInt(values.ALFA_Vertical_GRAD));

      this.set('rwinkelh', parseInt(values.ALFA_Horizontal_GRAD));
      this.set('lagenebentraeger', parseInt(values.SrVar));
      this.set('hthoehe', values.SrPaare);

      this.setX3D(parseInt(values.ALFA_Vertical_GRAD), rwinkelvEvent);
      this.setX3D(parseInt(values.ALFA_Horizontal_GRAD), rwinkelhEvent);

      this.send('validation');

      this.set('openFromFile', false);
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('rwinkelv').indexOf(",") !== -1) {
          this.set('rwinkelv', this.get('rwinkelv').replace(",", "."));
        }

        if (this.get('rwinkelh').indexOf(",") !== -1) {
          this.set('rwinkelh', this.get('rwinkelh').replace(",", "."));
        }
      }
    }).observes('rwinkelv', 'rwinkelh'),

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      var anschlussgeometrie = this.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

      anschlussgeometrie.set('SrVar', this.get('lagenebentraeger'));
      anschlussgeometrie.set('SrPaare', this.get('schraubenanordnung'));
    }).observes('lagenebentraeger', 'schraubenanordnung'),

    screwState: (function () {
      this.controllerFor('application').get('model').x3dddefault.objectAt(0).set('schraubenGekreuzt', this.get('schraubenanordnung'));

      var application = this.controllerFor('application');

      if (!this.get('schraubenanordnung')) {
        application.get('model').x3dddefault.objectAt(0).get('bauteile').forEach(function (item) {
          if (item.id.substring(0, 8) === 'schraube' && item.get('translations').objectAt(0).get('x') > 0) {

            var hWinkel = application.get('model').x3dddefault.objectAt(0).get('hWinkel') * Math.PI / 180;

            var itemTranslations = item.get('translations').objectAt(0);
            var itemLaenge = Math.cos(item.get('rotations').objectAt(0).get('z')) * item.get('screwProperties').objectAt(0).get('length');

            var zAlt = itemTranslations.get('z');
            itemTranslations.set('z', zAlt - Math.cos(hWinkel) * itemLaenge);
          }
        });
      } else {
        application.get('model').x3dddefault.objectAt(0).get('bauteile').forEach(function (item) {
          if (item.id.substring(0, 8) === 'schraube' && item.get('translations').objectAt(0).get('x') > 0) {

            var hWinkel = application.get('model').x3dddefault.objectAt(0).get('hWinkel') * Math.PI / 180;

            var itemTranslations = item.get('translations').objectAt(0);
            var itemLaenge = Math.cos(item.get('rotations').objectAt(0).get('z')) * item.get('screwProperties').objectAt(0).get('length');

            var zAlt = itemTranslations.get('z');
            itemTranslations.set('z', zAlt + Math.cos(hWinkel) * itemLaenge);
          }
        });
      }
      application.get('model').x3dddefault.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').x3dddefault.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').x3dddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3dddefault.objectAt(0).get('schraubenlisteAktualisiert'));
    }).observes('schraubenanordnung'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3dddefault = application.get('model').x3dddefault.objectAt(0);

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'rwinkelv':
          if (this.errors.get('rwinkelv').length === 0) {
            x3dddefault.set('vWinkel', value);

            var bauteile = x3dddefault.get('bauteile');
            var alpha = value * Math.PI / 180;

            bauteile.forEach(function (item) {
              if (item.get('typ') === 'standardSchraube') {
                var y = -(Math.sin(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).get('length')) - Math.abs(Math.tan(alpha) * (Math.cos(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).get('length') / 2));
                bauteile.findBy('id', item.id).get('translations').objectAt(0).set('y', y);
              }
            });
          }
          break;
        case 'rwinkelh':
          if (this.errors.get('rwinkelh').length === 0) {

            var winkelAlt = x3dddefault.get('hWinkel') * Math.PI / 180;

            x3dddefault.set('hWinkel', value);

            x3dddefault.get('bauteile').forEach(function (item) {
              if (item.id.substring(0, 8) === 'schraube') {

                var hWinkel = value * Math.PI / 180;
                item.get('rotations').objectAt(0).set('y', 1.5708 - hWinkel);

                var itemTranslations = item.get('translations').objectAt(0);

                var itemLaenge = Math.cos(item.get('rotations').objectAt(0).get('z')) * item.get('screwProperties').objectAt(0).get('length') / 2;

                var xVerschiebung = Math.sin(hWinkel) * itemLaenge;

                itemTranslations.set('x', -xVerschiebung);

                var zAlt = itemTranslations.get('z');
                if (!application.get('model').x3dddefault.objectAt(0).get('schraubenGekreuzt') && itemTranslations.get('x') > 0) {
                  itemTranslations.set('z', Math.abs(-zAlt - Math.cos(winkelAlt) * itemLaenge + Math.cos(hWinkel) * itemLaenge));
                } else {
                  itemTranslations.set('z', zAlt - Math.cos(winkelAlt) * itemLaenge + Math.cos(hWinkel) * itemLaenge);
                }
              }
            });
          }
          break;
      }

      application.get('model').x3dddefault.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').x3dddefault.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').x3dddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3dddefault.objectAt(0).get('schraubenlisteAktualisiert'));
      application.get('model').x3dddefault.objectAt(0).set('transformHelper', !application.get('model').x3dddefault.objectAt(0).get('transformHelper'));
    },

    actions: {
      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('anschlussgeometrieInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var anschlussgeometrie = self.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

            anschlussgeometrie.set('ALFA_Vertical_GRAD', parseFloat(self.get('rwinkelv')).toFixed(1));
            anschlussgeometrie.set('ALFA_Horizontal_GRAD', parseFloat(self.get('rwinkelh')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('anschlussgeometrieInvalid', true);
          }
        });
      },

      hWinkelIsSelected: function hWinkelIsSelected() {
        var hwinkelaktiv = this.controllerFor('application').get('model').x3dddefault.objectAt(0).get('hWinkelAktiv');
        this.controllerFor('application').get('model').x3dddefault.objectAt(0).set('hWinkelAktiv', !hwinkelaktiv);

        if (this.get('rwinkelh') !== "") {
          var rwinkelhwork = parseFloat(this.get('rwinkelh').replace(",", "."));
          var rwinkelhrounded = rwinkelhwork.toFixed(1);
          this.set('rwinkelh', rwinkelhrounded);
          this.send('validation');
        }
      },

      vWinkelIsSelected: function vWinkelIsSelected() {
        var vwinkelaktiv = this.controllerFor('application').get('model').x3dddefault.objectAt(0).get('vWinkelAktiv');
        this.controllerFor('application').get('model').x3dddefault.objectAt(0).set('vWinkelAktiv', !vwinkelaktiv);

        if (this.get('rwinkelv') != "") {
          var rwinkelvwork = parseFloat(this.get('rwinkelv').replace(",", "."));
          var rwinkelvrounded = rwinkelvwork.toFixed(1);
          this.set('rwinkelv', rwinkelvrounded);
          this.send('validation');
        }
      }

    }
  });

});