define('m10/components/cylinde-r', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Cylinder = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'cylinde-r',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var name = this.get('name');
      var x3ddefault = this.get('model').objectAt(0);
      var bauteile = x3ddefault.get('bauteile');
      var item = x3ddefault.get('bauteile').findBy('id', 'cylinder');

      var xVerschiebung, yVerschiebung, zVerschiebung;

      var values = '0 0 0';

      var reihen = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('vmReihen'));
      var spalten = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('vmReihenHorizontal'));

      if (Number(x3ddefault.get('vmittelart')) === 4) {
        xVerschiebung = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandaussen')) / (10 * (spalten - 1));
        xVerschiebung = xVerschiebung * Number(this.get('index') - 1) - Math.floor((this.get('index') - 1) / spalten) * spalten * xVerschiebung;

        yVerschiebung = 0;

        var n = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('vmReihen')) - 1;
        zVerschiebung = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandvmreihen') / 10) * Math.floor((this.get('index') - 1) / spalten);
      } else {

        if (spalten === 1) {
          xVerschiebung = 0;
        } else {
          xVerschiebung = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandaussen')) / (10 * (spalten - 1));
          xVerschiebung = xVerschiebung * Number(this.get('index') - 1) - Math.floor((this.get('index') - 1) / spalten) * spalten * xVerschiebung;
        }

        yVerschiebung = -Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandvmreihen') / 10) * Math.floor((this.get('index') - 1) / spalten);
        zVerschiebung = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z') / 20;

        // console.log('zweiseitig: '+bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('zweiseitig'));
        // console.log('index: '+this.get('index'));
        // console.log('reihen*spalten*2: '+reihen*spalten*2);

        if (bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('zweiseitig') && this.get('index') > reihen * spalten) {
          yVerschiebung = yVerschiebung + bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandvmreihen') / 10 * reihen;
          zVerschiebung = -zVerschiebung;
        }
      }
      values = xVerschiebung + " " + yVerschiebung + " " + zVerschiebung;

      return values;
    }).property('model.firstObject.cylindertrigger'),

    rotation: (function () {
      var name = this.get('name');
      var x3ddefault = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var values;

      if (Number(x3ddefault.get('vmittelart')) === 4) {
        values = '0 0 0 0';
      } else {
        values = bauteile.findBy('id', 'cylinder').get('rotations').objectAt(0).get('x') + ' ' + bauteile.findBy('id', 'cylinder').get('rotations').objectAt(0).get('y') + ' ' + bauteile.findBy('id', 'cylinder').get('rotations').objectAt(0).get('z') + ' ' + bauteile.findBy('id', 'cylinder').get('rotations').objectAt(0).get('winkel');
      }

      return values;
    }).property('model.firstObject.cylindertrigger'),

    center: (function () {
      var values = '0 0 0';

      return values;
    }).property(''),

    radius: (function () {
      var name = this.get('name');
      var x3ddefault = this.get('model').objectAt(0);
      var bauteile = x3ddefault.get('bauteile');
      var item = bauteile.findBy('id', 'cylinder');
      return item.get('cylindersizes').objectAt(0).get('radius') / 10;
    }).property('model.firstObject.cylindertrigger'),

    height: (function () {

      var name = this.get('name');
      var x3ddefault = this.get('model').objectAt(0);
      var bauteile = x3ddefault.get('bauteile');
      var item = bauteile.findBy('id', 'cylinder');
      return item.get('cylindersizes').objectAt(0).get('hoehe') / 10;
    }).property('model.firstObject.transformHelper'),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', 'cylinder');

      var emsvcolor = item.get('diffusecolors').objectAt(0).get('rot') + ' ' + item.get('diffusecolors').objectAt(0).get('gruen') + ' ' + item.get('diffusecolors').objectAt(0).get('blau');

      return emsvcolor;
    }).property('model.firstObject.spbreiteAktiv')

  });

  exports['default'] = Cylinder;

});