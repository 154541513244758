define('m10/components/appearanc-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Appearance = Ember['default'].Component.extend({
    tagName: 'appearance',
    layoutName: 'appearanc-e',
    attributeBindings: ['sorttype'],
    sorttype: "auto"
  });

  exports['default'] = Appearance;

});