define('m10/models/lasteinwirkung', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var lasteinwirkung = DS['default'].Model.extend({

		NKL_ID: DS['default'].attr('string'),
		NKL_Text: DS['default'].attr('string'),
		KLED_ID: DS['default'].attr('string'),
		LED_Text: DS['default'].attr('string'),
		F_90d: DS['default'].attr('string')

	});

	lasteinwirkung.reopenClass({
		FIXTURES: [{
			id: 1,
			LED_Text: "ständig",
			NKL_ID: 1,
			KLED_ID: 1,
			NKL_Text: "Nutzungsklasse 1",
			F_90d: "1.0"

		}]
	});

	exports['default'] = lasteinwirkung;

});