define('m10/controllers/queranschluss', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    displayErrors: true,
    openFromFile: false,

    gewindestahlstangen: false,
    disableEindringtiefe: false,

    eindringtiefetmp: 1,
    anzahlbauteiletmp: 1,

    vmdurchmesser: "",
    eindringtiefe: "",
    anzahlvmreihen: "",
    anzahlvmreihenhorizontal: "",
    abstandobererrand: "",
    abstandvmreihen: "",
    abstandaussen: "",
    abstandvmgruppen: "0.0",
    anzahlbauteile: true,
    vmittelart: 0,
    vmnurausserhalb: true,
    abstandvmgruppendisabled: true,
    vmnurausserhalbdisabled: false,

    ttDurchmesserVM: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [mm]";
    }),
    ttEindringtiefeVM: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttAnzahlReihen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "1 - 999 ";
    }),
    ttAbstandReihen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
    }),
    ttAbstandObererRand: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
    }),
    ttAussen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
    }),
    ttAnzahlReihenHorizontal: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "1 - 999 ";
    }),

    ttAbstandGruppen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      vmdurchmesser: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      eindringtiefe: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      anzahlvmreihen: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999
        }
      },
      abstandobererrand: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      abstandaussen: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      abstandvmreihen: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      anzahlvmreihenhorizontal: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999
        }
      },
      abstandvmgruppen: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.9
        }
      }
    },

    contentBauteilanzahlen: [],
    bauteilanzahlen: [{
      name: "einseitig",
      id: true
    }, {
      name: "zweiseitig",
      id: false
    }],

    contentVmittelarten: [],
    vmittelarten: [{
      name: "nagelSchrauben",
      id: 0,
      disabled: false
    }, {
      name: "naegelStahlblechHolz",
      id: 1,
      disabled: false
    }, {
      name: "stabduebelBolzen",
      id: 2,
      disabled: false
    }, {
      name: "duebelBesondereBauart",
      id: 3,
      disabled: false
    }, {
      name: "schraubenGewindestangen",
      id: 4,
      disabled: true
    }],

    init: function init() {
      this._super();
      var self = this;

      self.set('anzahlvmreihenhorizontal', '2');
      this.setSelectFieldsContent();
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in queranschluss: ");
        console.log(values);
      }

      var vmdurchmesserEvent = { target: { name: "vmdurchmesser" } };
      var eindringtiefeEvent = { target: { name: "eindringtiefe" } };
      var anzahlvmreihenEvent = { target: { name: "anzahlvmreihen" } };
      var abstandvmreihenEvent = { target: { name: "abstandvmreihen" } };
      var abstandobererrandEvent = { target: { name: "abstandobererrand" } };
      var abstandaussenEvent = { target: { name: "abstandaussen" } };
      var abstandvmgruppenEvent = { target: { name: "abstandvmgruppen" } };
      var anzahlvmreihenhorizontalEvent = { target: { name: "anzahlvmreihenhorizontal" } };

      this.set('openFromFile', true);

      this.set('vmittelart', parseInt(values.AnschlussID));
      this.set('vmdurchmesser', String(values.dConnectors * 10));
      this.set('eindringtiefe', values.tConnectors);
      this.set('anzahlbauteile', values.einseitig);
      this.set('anzahlvmreihen', values.n_vertikal);
      this.set('abstandvmreihen', values.a1_Connectors);
      this.set('abstandobererrand', values.h_1);
      this.set('abstandaussen', values.a_r);
      this.set('vmnurausserhalb', values.verstaerkungAusserhalb);
      this.set('abstandvmgruppen', values.l_g);
      this.set('anzahlvmreihenhorizontal', values.n_horizontal);

      this.setX3D(Number(values.dConnectors) * 10, vmdurchmesserEvent);
      this.setX3D(Number(values.tConnectors), eindringtiefeEvent);
      this.setX3D(Number(values.n_vertikal), anzahlvmreihenEvent);
      this.setX3D(Number(values.a1_Connectors), abstandvmreihenEvent);
      this.setX3D(Number(values.h_1), abstandobererrandEvent);
      this.setX3D(Number(values.a_r), abstandaussenEvent);
      this.setX3D(Number(values.l_g), abstandvmgruppenEvent);
      this.setX3D(Number(values.n_horizontal), anzahlvmreihenhorizontalEvent);

      this.set('openFromFile', true);

      this.send('validation');

      this.set('openFromFile', false);

      console.log(' QUERANSCHLUSS SET VALUES');
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setContentBauteilanzahlen();
      self.setContentVmittelarten();
    },

    setContentBauteilanzahlen: function setContentBauteilanzahlen() {
      var self = this;
      var indices = [true, false];
      self.set('contentBauteilanzahlen', self.getSelectFieldContent('contentBauteilanzahlen', indices));
    },

    setContentVmittelarten: function setContentVmittelarten() {
      var self = this;
      var indices = [0, 1, 2, 3, 4];
      self.set('contentVmittelarten', self.getSelectFieldContent('contentVmittelarten', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), value: dbContent[k].value, disabled: dbContent[k].disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {

        case 'contentBauteilanzahlen':
          dbContent = this.get('bauteilanzahlen');
          break;
        case 'contentVmittelarten':
          dbContent = this.get('vmittelarten');
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      var self = this;

      if (!self.openFromFile) {

        if (self.get('vmdurchmesser').indexOf(",") !== -1) {
          self.set('vmdurchmesser', self.get('vmdurchmesser').replace(",", "."));
        }

        if (self.get('eindringtiefe').indexOf(",") !== -1) {
          self.set('eindringtiefe', self.get('eindringtiefe').replace(",", "."));
        }

        if (self.get('anzahlvmreihen').indexOf(",") !== -1) {
          self.set('anzahlvmreihen', self.get('anzahlvmreihen').replace(",", "."));
        }

        if (self.get('abstandobererrand').indexOf(",") !== -1) {
          self.set('abstandobererrand', self.get('abstandobererrand').replace(",", "."));
        }

        if (self.get('abstandaussen').indexOf(",") !== -1) {
          self.set('abstandaussen', self.get('abstandaussen').replace(",", "."));
        }

        if (self.get('abstandvmreihen').indexOf(",") !== -1) {
          self.set('abstandvmreihen', self.get('abstandvmreihen').replace(",", "."));
        }

        if (self.get('anzahlvmreihenhorizontal').indexOf(",") !== -1) {
          self.set('anzahlvmreihenhorizontal', self.get('anzahlvmreihenhorizontal').replace(",", "."));
        }

        if (self.get('abstandvmgruppen').indexOf(",") !== -1) {
          self.set('abstandvmgruppen', self.get('abstandvmgruppen').replace(",", "."));
        }
      }
    }).observes('vmdurchmesser', 'eindringtiefe', 'anzahlvmreihen', 'abstandobererrand', 'abstandaussen', 'abstandvmreihen', 'anzahlvmreihenhorizontal', 'abstandvmgruppen'),

    watchComboboxes: (function () {

      var self = this;
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);

      this.set('openFromFile', true);

      if (self.get('vmittelart') === 4) {
        self.set('gewindestahlstangen', true);
        self.set('disableEindringtiefe', true);
        console.log(self.gewindestahlstangen);
        //self.set('eindringtiefetmp', self.get('eindringtiefe'));
        self.set('eindringtiefe', '0.0');
        //self.set('anzahlbauteiletmp', self.get('anzahlbauteile'));
        self.set('anzahlbauteile', false);
        self.set('validations.eindringtiefe.numericality.greaterThanOrEqualTo', 0);
      } else {
        self.set('gewindestahlstangen', false);
        // console.log(self.gewindestahlstangen);
        self.set('disableEindringtiefe', false);
        self.set('validations.eindringtiefe.numericality.greaterThanOrEqualTo', 0.1);
        self.set('anzahlbauteile', true);
        if (self.get('vmittelart') === 1) {
          self.setQueranschluss(true);
        } else {
          self.setQueranschluss(false);
        }

        self.set('openFromFile', true);
        console.log('watchComboboxes');
        self.send('validation');

        self.set('openFromFile', false);

        //self.set('eindringtiefe', self.eindringtiefetmp);
        //self.set('anzahlbauteile', self.anzahlbauteiletmp);
      }

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      var queranschluss = this.controllerFor('application').get('model').queranschluss.objectAt(0);
      queranschluss.set('AnschlussID', this.get('vmittelart'));

      x3d.set('vmittelart', self.get('vmittelart'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));

      this.set('openFromFile', false);
    }).observes('vmittelart'),

    watchAnzahlBauteile: (function () {
      var self = this;
      var queranschluss = this.controllerFor('application').get('model').queranschluss.objectAt(0);
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);

      queranschluss.set('einseitig', this.get('anzahlbauteile'));

      if (self.get('vmittelart') !== 4) {
        x3d.get('bauteile').findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).set('zweiseitig', !this.get('anzahlbauteile'));
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }).observes('anzahlbauteile'),

    watchVerstaerkungAusserhalb: (function () {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      var queranschluss = this.controllerFor('application').get('model').queranschluss.objectAt(0);
      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);

      if (this.get('vmnurausserhalb')) {
        this.set('anzahlvmreihenhorizontal', "2");
        x3d.get('bauteile').findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).set('vmReihenHorizontal', 2);
      }
      queranschluss.set('verstaerkungAusserhalb', this.get('vmnurausserhalb'));

      x3d.set('transformHelper', !x3d.get('transformHelper'));
    }).observes('vmnurausserhalb'),

    reValidate: function reValidate() {
      var self = this;
      self.set('openFromFile', true);
      self.send('validation');
      self.set('openFromFile', false);
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('queranschlussInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var queranschluss = self.controllerFor('application').get('model').queranschluss.objectAt(0);

            queranschluss.set('dConnectors', parseFloat(self.get('vmdurchmesser') / 10).toFixed(1));
            queranschluss.set('tConnectors', parseFloat(self.get('eindringtiefe')).toFixed(1));
            queranschluss.set('n_vertikal', parseFloat(self.get('anzahlvmreihen')));
            queranschluss.set('n_horizontal', parseFloat(self.get('anzahlvmreihenhorizontal')));
            queranschluss.set('h_1', parseFloat(self.get('abstandobererrand')).toFixed(1));
            queranschluss.set('a_r', parseFloat(self.get('abstandaussen')).toFixed(1));
            queranschluss.set('a1_Connectors', parseFloat(self.get('abstandvmreihen')).toFixed(1));
            queranschluss.set('l_g', parseFloat(self.get('abstandvmgruppen')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('queranschlussInvalid', true);
          }
        });
      },

      vmdurchmesserIsSelected: function vmdurchmesserIsSelected() {
        var vmdurchmesserAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('vmdurchmesserAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('vmdurchmesserAktiv', !vmdurchmesserAktiv);

        if (this.get('vmdurchmesser') !== "") {
          var vmdurchmesserwork = parseFloat(this.get('vmdurchmesser').replace(",", "."));
          var vmdurchmesserrounded = vmdurchmesserwork.toFixed(1);
          this.set('vmdurchmesser', vmdurchmesserrounded);
          this.send('validation', vmdurchmesserrounded, { target: { name: "vmdurchmesser" } });
        }

        if (vmdurchmesserAktiv === false) {
          document.getElementsByName('vmdurchmesser')[0].setSelectionRange(0, this.get('vmdurchmesser').length);
        }
      },

      eindringtiefeIsSelected: function eindringtiefeIsSelected() {
        var eindringtiefeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('eindringtiefeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('eindringtiefeAktiv', !eindringtiefeAktiv);

        if (this.get('eindringtiefe') !== "") {
          var eindringtiefework = parseFloat(this.get('eindringtiefe').replace(",", "."));
          var eindringtieferounded = eindringtiefework.toFixed(1);
          this.set('eindringtiefe', eindringtieferounded);
          this.send('validation', eindringtieferounded, { target: { name: "eindringtiefe" } });
        }

        if (eindringtiefeAktiv === false) {
          document.getElementsByName('eindringtiefe')[0].setSelectionRange(0, this.get('eindringtiefe').length);
        }
      },

      anzahlvmreihenIsSelected: function anzahlvmreihenIsSelected() {
        var anzahlvmreihenAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('anzahlvmreihenAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('anzahlvmreihenAktiv', !anzahlvmreihenAktiv);

        if (this.get('anzahlvmreihen') !== "") {
          var anzahlvmreihenwork = parseFloat(this.get('anzahlvmreihen').replace(",", "."));
          var anzahlvmreihenrounded = anzahlvmreihenwork.toFixed(0);
          this.set('anzahlvmreihen', anzahlvmreihenrounded);
          this.send('validation', anzahlvmreihenrounded, { target: { name: "anzahlvmreihen" } });
        }

        if (anzahlvmreihenAktiv === false) {
          document.getElementsByName('anzahlvmreihen')[0].setSelectionRange(0, this.get('anzahlvmreihen').length);
        }
      },

      anzahlvmreihenhorizontalIsSelected: function anzahlvmreihenhorizontalIsSelected() {
        var anzahlvmreihenhorizontalAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('anzahlvmreihenhorizontalAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('anzahlvmreihenhorizontalAktiv', !anzahlvmreihenhorizontalAktiv);

        if (this.get('anzahlvmreihenhorizontal') !== "") {
          var anzahlvmreihenhorizontalwork = parseFloat(this.get('anzahlvmreihenhorizontal').replace(",", "."));
          var anzahlvmreihenhorizontalrounded = anzahlvmreihenhorizontalwork.toFixed(0);
          this.set('anzahlvmreihenhorizontal', anzahlvmreihenhorizontalrounded);
          this.send('validation', anzahlvmreihenhorizontalrounded, { target: { name: "anzahlvmreihenhorizontal" } });
        }

        if (anzahlvmreihenhorizontalAktiv === false) {
          document.getElementsByName('anzahlvmreihenhorizontal')[0].setSelectionRange(0, this.get('anzahlvmreihenhorizontal').length);
        }
      },

      abstandvmreihenIsSelected: function abstandvmreihenIsSelected() {
        var abstandvmreihenAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('abstandvmreihenAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('abstandvmreihenAktiv', !abstandvmreihenAktiv);

        if (this.get('abstandvmreihen') !== "") {
          var abstandvmreihenwork = parseFloat(this.get('abstandvmreihen').replace(",", "."));
          var abstandvmreihenrounded = abstandvmreihenwork.toFixed(1);
          this.set('abstandvmreihen', abstandvmreihenrounded);
          this.send('validation', abstandvmreihenrounded, { target: { name: "abstandvmreihen" } });
        }

        if (abstandvmreihenAktiv === false) {
          document.getElementsByName('abstandvmreihen')[0].setSelectionRange(0, this.get('abstandvmreihen').length);
        }
      },

      abstandobererrandIsSelected: function abstandobererrandIsSelected() {
        var abstandobererrandAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('abstandobererrandAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('abstandobererrandAktiv', !abstandobererrandAktiv);

        if (this.get('abstandobererrand') !== "") {
          var abstandobererrandwork = parseFloat(this.get('abstandobererrand').replace(",", "."));
          var abstandobererrandrounded = abstandobererrandwork.toFixed(1);
          this.set('abstandobererrand', abstandobererrandrounded);
          this.send('validation', abstandobererrandrounded, { target: { name: "abstandobererrand" } });
        }

        if (abstandobererrandAktiv === false) {
          document.getElementsByName('abstandobererrand')[0].setSelectionRange(0, this.get('abstandobererrand').length);
        }
      },

      abstandaussenIsSelected: function abstandaussenIsSelected() {
        var abstandaussenAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('abstandaussenAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('abstandaussenAktiv', !abstandaussenAktiv);

        if (this.get('abstandaussen') !== "") {
          var abstandaussenwork = parseFloat(this.get('abstandaussen').replace(",", "."));
          var abstandaussenrounded = abstandaussenwork.toFixed(1);
          this.set('abstandaussen', abstandaussenrounded);
          this.send('validation', abstandaussenrounded, { target: { name: "abstandaussen" } });
        }

        if (abstandaussenAktiv === false) {
          document.getElementsByName('abstandaussen')[0].setSelectionRange(0, this.get('abstandaussen').length);
        }
      },

      abstandvmgruppenIsSelected: function abstandvmgruppenIsSelected() {
        var abstandvmgruppenAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('abstandvmgruppenAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('abstandvmgruppenAktiv', !abstandvmgruppenAktiv);

        if (this.get('abstandvmgruppen') !== "") {
          var abstandvmgruppenwork = parseFloat(this.get('abstandvmgruppen').replace(",", "."));
          var abstandvmgruppenrounded = abstandvmgruppenwork.toFixed(1);
          this.set('abstandvmgruppen', abstandvmgruppenrounded);
          this.send('validation', abstandvmgruppenrounded, { target: { name: "abstandvmgruppen" } });
        }

        if (abstandvmgruppenAktiv === false) {
          document.getElementsByName('abstandvmgruppen')[0].setSelectionRange(0, this.get('abstandvmgruppen').length);
        }
      }
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var self = this;

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'vmdurchmesser':
          if (this.errors.vmdurchmesser.length === 0) {
            bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).set('radius', value / 20);
            application.get('model').x3ddefault.objectAt(0).set('vmdurchmesserEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('vmdurchmesserEingetragen', false);
          }
          x3d.set('cylindertrigger', !x3d.get('cylindertrigger'));

          break;
        case 'eindringtiefe':
          if (this.errors.eindringtiefe.length === 0) {
            bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).set('hoehe', value * 2);
            application.get('model').x3ddefault.objectAt(0).set('eindringtiefeEingetragen', true);
            if (!x3d.get('htBreiteEingetragen')) {
              bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('z', value * 2);
            }
          } else {
            application.get('model').x3ddefault.objectAt(0).set('eindringtiefeEingetragen', false);
          }
          break;
        case 'anzahlvmreihen':
          if (this.errors.anzahlvmreihen.length === 0) {
            bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).set('vmReihen', value);
            application.get('model').x3ddefault.objectAt(0).set('anzahlvmreihenEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('anzahlvmreihenEingetragen', false);
          }
          x3d.set('cylindertrigger', !x3d.get('cylindertrigger'));
          break;
        case 'anzahlvmreihenhorizontal':
          if (this.errors.anzahlvmreihenhorizontal.length === 0) {
            bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).set('vmReihenHorizontal', value);
            application.get('model').x3ddefault.objectAt(0).set('anzahlvmreihenhorizontalEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('anzahlvmreihenhorizontalEingetragen', false);
          }
          x3d.set('cylindertrigger', !x3d.get('cylindertrigger'));
          break;
        case 'abstandvmreihen':
          if (this.errors.abstandvmreihen.length === 0) {
            bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).set('abstandvmreihen', value);
            application.get('model').x3ddefault.objectAt(0).set('abstandvmreihenEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('abstandvmreihenEingetragen', false);
          }
          break;
        case 'abstandobererrand':
          if (this.errors.abstandobererrand.length === 0) {
            bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).set('abstandobererrand', value);
            if (x3d.get('vmittelart') === 4) {
              value = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') - value;
              bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).set('hoehe', value);
            }
            application.get('model').x3ddefault.objectAt(0).set('abstandobererrandEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('abstandobererrandEingetragen', false);
          }
          break;
        case 'abstandaussen':
          if (this.errors.abstandaussen.length === 0) {
            bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).set('abstandaussen', value);
            application.get('model').x3ddefault.objectAt(0).set('abstandaussenEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('abstandaussenEingetragen', false);
          }
          break;
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      this.controllerFor('application').zentriertObjekt();
    },

    setQueranschluss: function setQueranschluss(stahlblechHolz) {

      var self = this;
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var queranschluss = bauteile.findBy('id', 'queranschluss1');
      var z = queranschluss.get('boxsizes').objectAt(0).get('x');
      var rot = 0.52,
          gruen = 0.385,
          blau = 0.02;

      if (stahlblechHolz) {
        if (!x3d.get('eindringtiefeEingetragen')) {
          z = 0.5;
        }
        rot = 0;
        gruen = 0;
        blau = 0.2;
      }
      bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).set('hoehe', z);
      queranschluss.get('diffusecolors').objectAt(0).set('rot', rot);
      queranschluss.get('diffusecolors').objectAt(0).set('gruen', gruen);
      queranschluss.get('diffusecolors').objectAt(0).set('blau', blau);
    }

  });

});