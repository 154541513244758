define('m10/components/transfor-m', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'transfor-m',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    // center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",
    test: 1337,

    center: (function () {
      var values = '0 0 0';

      return values;
    }).property(''),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item;
      if (name === 'queranschluss2') {
        item = this.get('model').objectAt(0).get('bauteile').findBy('id', 'queranschluss1');
      } else {
        item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      }

      var values = '0 0 0';
      var y;
      var yVerschiebung;
      var spalten = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('vmReihenHorizontal'));

      switch (name) {
        case 'queranschluss1':
          var xVerschiebung = 0;
          yVerschiebung = -item.get('boxsizes').objectAt(0).get('y') / 10 + 4 * x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 60;
          if (x3d.get('abstandobererrandEingetragen')) {
            yVerschiebung = -item.get('boxsizes').objectAt(0).get('y') / 10 + x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 10;
          }
          var zVerschiebung = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).get('hoehe') / 40;
          values = xVerschiebung + " " + yVerschiebung + " " + zVerschiebung;
          break;
        case 'queranschluss2':
          var xVerschiebung = 0;
          yVerschiebung = -item.get('boxsizes').objectAt(0).get('y') / 10 + 4 * x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 60;
          if (x3d.get('abstandobererrandEingetragen')) {
            yVerschiebung = -item.get('boxsizes').objectAt(0).get('y') / 10 + x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 10;
          }
          var zVerschiebung = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).get('hoehe') / 40;
          values = xVerschiebung + " " + yVerschiebung + " " + -zVerschiebung;
          break;
        case 'nebenTraeger':
          yVerschiebung = -item.get('boxsizes').objectAt(0).get('x') / 10;
          values = 0 + " " + yVerschiebung + " " + 0;
          break;
        case 'cylinder':
          var zVerschiebung = 0;
          var xVerschiebung = 0;
          if (Number(x3d.get('vmittelart')) === 4) {
            xVerschiebung = -(Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandaussen')) / 20);
            var n = bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('vmReihen') - 1;
            yVerschiebung = x3d.get('bauteile').findBy('id', 'cylinder').get('cylindersizes').objectAt(0).get('hoehe') / 20;
            zVerschiebung = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandvmreihen') / 10) * -n / 2;
          } else {
            xVerschiebung = -(Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandaussen')) / 20);
            yVerschiebung = 4 * x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 60 - x3d.get('bauteile').findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandobererrand') / 10;
            if (x3d.get('abstandobererrandEingetragen')) {
              yVerschiebung = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 10 - x3d.get('bauteile').findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandobererrand') / 10;
            }
          }

          if (spalten === 1) {
            xVerschiebung = 0;
          }

          values = xVerschiebung + " " + yVerschiebung + " " + zVerschiebung;
          break;
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }
      var x3d = this.get('model');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var values;

      if (name.substring(0, 8) === 'schraube' || name === 'cylinder') {
        values = '0 0 0 0';
      } else {
        values = bauteile.findBy('id', name).get('rotations').objectAt(0).get('x') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('y') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('z') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('winkel');
      }
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    getOutline: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var istVolumen = false;

      if (bauteile.findBy('id', name).get('typ') === 'box') {
        istVolumen = true;
      }
      return istVolumen;
    }).property(''),

    getMasskette: (function () {
      var value = false;

      if (this.get('name') === 'traeger') {
        value = true;
      }

      return value;
    }).property(''),

    abstandvmreihenAnzeigen: (function () {
      var value = false;
      if (Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('vmReihen')) > 1) {
        value = true;
      }
      return value;
    }).property('model.firstObject.anzahlvmreihenAktiv', 'model.firstObject.bauteile.firstObject.anschlussparameter.firstObject.vmReihen'),

    abstandvmreihenHorizontalAnzeigen: (function () {
      var value = false;
      if (Number(this.get('model').objectAt(0).get('bauteile').findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('vmReihenHorizontal')) > 1) {
        value = true;
      }
      return value;
    }).property('model.firstObject.anzahlvmreihenhorizontalAktiv'),

    istCylinder: (function () {
      var name = this.get('name');
      if (name.substring(0, 13) === 'queranschluss') {
        name = 'queranschluss1';
      }

      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      var istcylinder = false;

      if (item.get('typ') === "cylinder") {
        istcylinder = true;
      }

      return istcylinder;
    }).property(''),

    cylinderListe: (function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var vmreihenhorizontal = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('vmReihenHorizontal'));
      var count = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('vmReihen')) * vmreihenhorizontal;
      if (bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('zweiseitig') && Number(x3d.get('vmittelart')) !== 4) {
        count = count * 2;
      }
      var liste = Ember['default'].Set.create();
      for (var i = 1; i < count + 1; i++) {
        var item = { index: i, name: "cylinder" + i };
        liste.add(item);
      }

      return liste.toArray();
    }).property('model.firstObject.transformHelper', 'model.firstObject.vmAktualisiert'),

    istAnschlussUnten: (function () {
      var value = true;
      var x3d = this.get('model').objectAt(0);

      if (x3d.get('vmittelart') === 4) {
        value = false;
      }

      return value;
    }).property('model.firstObject.transformHelper'),

    istQueranschluss1: (function () {
      var value = false;
      var name = this.get('name');

      if (name === 'queranschluss1') {
        value = true;
      }

      return value;
    }).property('model.firstObject.transformHelper'),

    abstaendeAnzeigen: (function () {
      var x3d = this.get('model').objectAt(0);
      return x3d.get('abstaendeAnzeigen');
    }).property('model.firstObject.abstaendeAnzeigen'),

    ansichtLinks: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }
      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtFront: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true && name !== 'lager1' || bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') && name === 'lager1') {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtOben: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint')

  });

  exports['default'] = Transform;

});