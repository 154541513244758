define('m10/models/anschlussparameter', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		vmtyp: DS['default'].attr('string'),
		vmReihen: DS['default'].attr('number'),
		vmReihenHorizontal: DS['default'].attr('number'),
		abstandobererrand: DS['default'].attr('number'),
		abstandvmreihen: DS['default'].attr('number'),
		abstandaussen: DS['default'].attr('number'),
		zweiseitig: DS['default'].attr('boolean')
	});

});