define('m10/controllers/traeger', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,
    ttHoeheHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "10 - 999.9 [cm]";
    }),
    ttBreiteHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "5 - 999.9 [cm]";
    }),
    ttHoeheNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "10 - 999.9 [cm]";
    }),
    ttBreiteNT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "5 - 999.9 [cm]";
    }),
    i18n: Ember['default'].inject.service(),
    self: undefined,
    hauptnebentraeger: '',
    htbreite: "",
    hthoehe: "",
    ntbreite: "",
    nthoehe: "",
    toolTip: "test tooltip",
    htfkl: 5,
    ntfkl: 5,
    htholz: 0,
    ntholz: 0,
    httraeger: true,

    validations: {
      htbreite: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 999.9
        }
      },
      hthoehe: {
        numericality: {
          greaterThanOrEqualTo: 10,
          lessThanOrEqualTo: 999.9
        }
      }
    },

    traeger: [{
      name: "Haupt-/Nebenträger",
      value: true
    }, {
      name: "Stütze/Nebenträger",
      value: false
    }],

    htmaterial: [],

    ntmaterial: [],

    fkl_vh: [],

    fkl_bsh: [],

    fkl_bsh_kombi: [],

    htfklarray: [],
    ntfklarray: [],

    init: function init() {
      this._super();

      this.setSelectFieldsContent();

      this.set('hauptnebentraeger', this.get('i18n').t('bemessungslast').toString());
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      var htbreiteEvent = { target: { name: "htbreite" } };
      var hthoeheEvent = { target: { name: "hthoehe" } };

      this.set('openFromFile', true);

      this.set('ntholz', parseInt(values.MatKeyTimberElement));
      this.set('ntfkl', parseInt(values.FKLKeyTimberElement));

      this.set('htbreite', values.TimberElement_b);
      this.set('hthoehe', values.TimberElement_h);

      this.setX3D(values.TimberElement_b, htbreiteEvent);
      this.setX3D(values.TimberElement_h, hthoeheEvent);

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setNtMaterialien();
      self.setNtFKL();
    },

    setNtMaterialien: function setNtMaterialien() {
      var self = this;
      var indices = [0, 1, 13];
      self.set('ntmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setNtFKL: function setNtFKL(indices) {
      var self = this;
      var indices = [];

      // this.set('htfklarray', self.getFklassen(self.get('htholz')));

      if (this.get('ntholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('ntfkl', 20);
      } else if (self.get('ntholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('ntfkl', 30);
      } else if (self.get('ntholz') === 0) {
        indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        self.set('ntfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('ntfkl', 5);
      }
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name) });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de_m03;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;
        case 'fkl_vollholz_laubholz':
          dbContent = fklDB_new.fkl_vollholz_laubholz.fklassen;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('htbreite').indexOf(",") !== -1) {
          this.set('htbreite', this.get('htbreite').replace(",", "."));
        }

        if (this.get('hthoehe').indexOf(",") !== -1) {
          this.set('hthoehe', this.get('hthoehe').replace(",", "."));
        }

        if (this.get('ntbreite').indexOf(",") !== -1) {
          this.set('ntbreite', this.get('ntbreite').replace(",", "."));
        }

        if (this.get('nthoehe').indexOf(",") !== -1) {
          this.set('nthoehe', this.get('nthoehe').replace(",", "."));
        }
      }
    }).observes('htbreite', 'hthoehe', 'ntbreite', 'nthoehe'),

    changeHtSt: (function () {
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('istHt', this.get('httraeger'));
    }).observes('httraeger'),

    istHt: (function () {
      return this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('istHt');
    }).property('httraeger'),

    watchHtMaterial: (function () {
      console.log(this.title);
      if (this.get('htholz') === 1) {
        this.set('htfklarray', this.fkl_bsh);
        this.set('htfkl', 20);
      } else if (this.get('htholz') === 13) {
        this.set('htfklarray', this.fkl_bsh_kombi);
        this.set('htfkl', 30);
      } else {
        this.set('htfklarray', this.fkl_vh);
        this.set('htfkl', 5);
      }
    }).observes('htholz'),

    watchNtMaterial: (function () {
      /*if (this.get('ntholz') === 1) {
        this.set('ntfklarray', this.fkl_bsh);
        this.set('ntfkl', 20);
      } else if (this.get('ntholz') === 13) {
        this.set('ntfklarray', this.fkl_bsh_kombi);
        this.set('ntfkl', 30);
      } else {
        this.set('ntfklarray', this.fkl_vh);
        this.set('ntfkl', 5);
      }*/

      this.setNtFKL();
    }).observes('ntholz'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var self = this;

      application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').x3ddefault.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').x3ddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3ddefault.objectAt(0).get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'htbreite':
          if (this.errors.htbreite.length === 0) {
            bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('z', value);
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htBreiteEingetragen', false);
          }
          var queranschluss = self.controllerFor('queranschluss');
          queranschluss.set('validations.eindringtiefe.numericality.lessThanOrEqualTo', Number(value));
          queranschluss.set('ttEindringtiefeVM', self.get('i18n').t('wertebereich') + " 0.1 - " + value + " [cm]");
          // this.controllerFor('queranschluss').reValidate();
          break;
        case 'hthoehe':
          if (this.errors.hthoehe.length === 0) {
            bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('y', value);
            bauteile.findBy('id', 'queranschluss1').get('boxsizes').objectAt(0).set('y', Number(value) + 20);
            // if (x3d.get('abstandobererrandEingetragen')){
            //   var h = Number(value) - Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandobererrand'));
            //   bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).set('hoehe', h.toFixed(1));
            // }
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('htHoeheEingetragen', false);
          }
          break;
      }
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      this.controllerFor('application').zentriertObjekt();
    },

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      var traeger = this.controllerFor('application').get('model').traeger.objectAt(0);

      traeger.set('MatKeyTimberElement', this.get('ntholz'));
      traeger.set('FKLKeyTimberElement', this.get('ntfkl'));

      self.controllerFor('lasteinwirkung').setNklassen();
      self.controllerFor('lasteinwirkung').send('validation', self.controllerFor('lasteinwirkung').get('vorbelastung'), { target: { name: "vorbelastung" } });
    }).observes('ntholz', 'ntfkl'),

    setSchraubenlaenge: function setSchraubenlaenge() {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var hWinkel = x3d.get('hWinkel') * Math.PI / 180;
      var vWinkel = x3d.get('vWinkel') * Math.PI / 180;

      var htHoehe = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y');
      var ntHoehe = x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('x');

      var breiteHT = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('x');

      var values = [htHoehe * 2 / 3, ntHoehe * 2 / 3, breiteHT * 2 / 3];

      values.sort(function (a, b) {
        return a - b;
      });

      bauteile.forEach(function (item) {
        if (item.get('typ') === 'standardSchraube') {

          var schraubenLaengeAlt = bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).get('length');

          var schraubenLaenge = values[0] / Math.sin(x3d.get('bauteile').findBy('id', item.id).get('rotations').objectAt(0).get('z'));

          var y = -(Math.sin(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaenge) - Math.abs(Math.tan(vWinkel) * (Math.cos(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaenge / 2));

          var laengeAltRelativ = Math.cos(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaengeAlt / 2;
          var laengeNeuRelativ = Math.cos(bauteile.findBy('id', item.id).get('rotations').objectAt(0).get('z')) * schraubenLaenge / 2;

          var zAlt = bauteile.findBy('id', item.id).get('translations').objectAt(0).get('z');

          bauteile.findBy('id', item.id).get('translations').objectAt(0).set('x', -(Math.sin(hWinkel) * (Math.cos(item.get('rotations').objectAt(0).get('z')) * schraubenLaenge / 2)));
          bauteile.findBy('id', item.id).get('translations').objectAt(0).set('y', y);

          if (!application.get('model').x3ddefault.objectAt(0).get('schraubenGekreuzt') && bauteile.findBy('id', item.id).get('translations').objectAt(0).get('x') > 0) {
            bauteile.findBy('id', item.id).get('translations').objectAt(0).set('z', Math.abs(-zAlt - Math.cos(hWinkel) * laengeAltRelativ + Math.cos(hWinkel) * laengeNeuRelativ));
          } else {
            bauteile.findBy('id', item.id).get('translations').objectAt(0).set('z', zAlt - Math.cos(hWinkel) * laengeAltRelativ + Math.cos(hWinkel) * laengeNeuRelativ);
          }
          bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).set('length', schraubenLaenge);
          bauteile.findBy('id', item.id).get('screwProperties').objectAt(0).set('threadlengthtip', schraubenLaenge);
        }
      });
    },

    setZPosSchraube: function setZPosSchraube() {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var ntBreite = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z');
      var htBreite = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y');

      application.get('model').x3ddefault.objectAt(0).get('bauteile').forEach(function (item) {
        if (item.id.substring(0, 8) === 'schraube') {

          var hWinkel = application.get('model').x3ddefault.objectAt(0).get('hWinkel') * Math.PI / 180;
          var itemTranslations = item.get('translations').objectAt(0);
          var itemLaenge = Math.cos(item.get('rotations').objectAt(0).get('z')) * item.get('screwProperties').objectAt(0).get('length') / 2;

          if (x3d.get('istHt') || Number(htBreite) > ntBreite) {
            if (itemTranslations.get('x') < 0) {
              itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge - ntBreite / 5.5);
            } else {
              if (x3d.get('schraubenGekreuzt')) {
                itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge + ntBreite / 5.5);
              } else {
                itemTranslations.set('z', Math.abs(Math.cos(hWinkel) * itemLaenge) + ntBreite / 5.5);
              }
            }
          } else {
            if (itemTranslations.get('x') < 0) {
              itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge - htBreite / 5.5);
            } else {
              if (x3d.get('schraubenGekreuzt')) {
                itemTranslations.set('z', Math.cos(hWinkel) * itemLaenge + htBreite / 5.5);
              } else {
                itemTranslations.set('z', Math.abs(Math.cos(hWinkel) * itemLaenge) + htBreite / 5.5);
              }
            }
          }
        }
      });
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('traegerInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            // var queranschluss = self.controllerFor('queranschluss');

            // queranschluss.set('validations.eindringtiefe.numericality.lessThanOrEqualTo', parseFloat(self.get('htbreite')));
            // queranschluss.set('ttEindringtiefeVM', self.get('i18n').t('wertebereich') + " 0.1 - " + (parseFloat(self.get('htbreite')).toFixed(1) + " [mm]"));

            var traeger = self.controllerFor('application').get('model').traeger.objectAt(0);

            traeger.set('TimberElement_b', parseFloat(self.get('htbreite')).toFixed(1));
            traeger.set('TimberElement_h', parseFloat(self.get('hthoehe')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('traegerInvalid', true);
          }
        });
      },

      htbreiteIsSelected: function htbreiteIsSelected() {
        var htbreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('htbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('htbreiteAktiv', !htbreiteaktiv);

        if (this.get('htbreite') !== "") {
          var htbreitework = parseFloat(this.get('htbreite').replace(",", "."));
          var htbreiterounded = htbreitework.toFixed(1);
          this.set('htbreite', htbreiterounded);
          this.send('validation', htbreiterounded, { target: { name: "htbreite" } });
        }

        if (htbreiteaktiv === false) {
          document.getElementsByName('htbreite')[0].setSelectionRange(0, this.get('htbreite').length);
        }
      },

      hthoeheIsSelected: function hthoeheIsSelected() {
        var hthoeheaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hthoeheAktiv', !hthoeheaktiv);

        if (this.get('hthoehe') !== "") {
          var hthoehework = parseFloat(this.get('hthoehe').replace(",", "."));
          var hthoeherounded = hthoehework.toFixed(1);
          this.set('hthoehe', hthoeherounded);
          this.send('validation', hthoeherounded, { target: { name: "hthoehe" } });
        }

        if (hthoeheaktiv === false) {
          document.getElementsByName('hthoehe')[0].setSelectionRange(0, this.get('hthoehe').length);
        }
      }
    }
  });

});