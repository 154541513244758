define('m10/components/schraub-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schraube = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraub-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      var name = this.get('name');

      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';

      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');

      var positionierungsFaktor = 1;
      var drehwinckelZAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('z');

      if (!this.get('model').objectAt(0).get('schraubenGekreuzt') && drehwinckelZAchse > 1.5707) {
        positionierungsFaktor = -1;
      }

      var x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x') / 10 * positionierungsFaktor;
      var y = bauteile.findBy('id', name).get('translations').objectAt(0).get('y') / 10;
      var z = bauteile.findBy('id', name).get('translations').objectAt(0).get('z') / 10;

      values = x + ' ' + y + ' ' + z;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    // Rotation in der XY-Ebene, um die z-Achse
    rotation: (function () {
      var name = this.get('name');
      var drehwinckelZAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('z');
      var drehwinckelYAchse = this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('y');
      var values = '';

      // if (drehwinckelZAchse === 0){

      values = '0 0 1 ' + 3.1416;

      // }else{
      //
      //   if ((!this.get('model').objectAt(0).get('schraubenGekreuzt')) && (drehwinckelZAchse > 1.5707)){
      //     drehwinckelZAchse = 1.5707-(drehwinckelZAchse-1.5707);
      //   }
      //
      //   var xWertRotationsachse = Math.sin(drehwinckelYAchse);
      //   var zWertRotationsachse = Math.cos(drehwinckelYAchse);
      //
      //   values = xWertRotationsachse + ' 0 '+ zWertRotationsachse + ' ' + (drehwinckelZAchse-1.5707);
      //
      // }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt')
  });

  exports['default'] = Schraube;

});