define('m10/initializers/ember-tooltips', ['exports', 'ember', 'm10/config/environment', 'm10/mixins/components/tooltips'], function (exports, Ember, ENV, Tooltips) {

  'use strict';

  exports.initialize = initialize;

  function initialize() {
    var defaultOptions = {
      addTo: ['Component']
    };
    var overridingOptions = ENV['default'].tooltips || {};
    var options = Ember['default'].merge(defaultOptions, overridingOptions);

    /* TODO - Needs test coverage for addTo */

    if (Ember['default'].typeOf(options.addTo) === 'array') {
      options.addTo.forEach(function (className) {
        Ember['default'][className].reopen(Tooltips['default']);
      });
    }
  }

  exports['default'] = {
    name: 'ember-tooltips',
    initialize: initialize
  };

});