define('m10/routes/verbindungsmittel', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({

			setupController: function setupController() {
					this.controllerFor('application').miniertX3D();
			}

	});

});