define('m10/components/tex-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var x3dText = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    austrittspunktRechts: false,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var variable = this.get('variable');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;
      // var skalierungsfaktor = x3d.get('skalierungsfaktor')+0.6;
      var skalierungsfaktor = 1;

      var item = x3d.get('bauteile').findBy('id', name);

      var width = 8 * bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).get('radius') / 10 + bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandaussen') / 10;

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var abstandVonDerMasslinie = 0.05;

      switch (variable) {
        case 'breite':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = -1.5 * width - 2 * hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
            z = 0;
          } else {
            x = -1.5 * width;
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10 + 2 * hoeheMasskette + abstandVomBauteil + abstandVonDerMasslinie;
            z = 0;
          }
          break;
        case 'hoehe':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = -1.5 * width;
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;
            z = -bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 - 2 * hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
          } else {
            x = -1.5 * width - 2 * hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20;
            z = -bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
          }
          break;
        case 'abstandobererrand':

          var yVerschiebung = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandobererrand') / 20;

          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = -1.5 * width;
            y = yVerschiebung;
            z = -bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 - hoeheMasskette - abstandVonDerMasslinie;
          } else {
            x = -1.5 * width - hoeheMasskette - abstandVonDerMasslinie;
            y = yVerschiebung;
            z = -bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
          }
          break;
        case 'abstandvmreihen':
          if (x3d.get('vmittelart') === 4) {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -1.5 * width - hoeheMasskette - abstandVonDerMasslinie;
              y = 0;
              z = 0;
            } else {
              x = -width;
              y = -0.8 - hoeheMasskette - abstandVonDerMasslinie;
              z = 0;
            }
          } else {
            var yVerschiebung = 4 * Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y')) / 60 - Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandobererrand')) / 10 - Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandvmreihen')) / 20;
            if (x3d.get('abstandobererrandEingetragen')) {
              yVerschiebung = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y')) / 10 - Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandobererrand')) / 10 - Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandvmreihen')) / 20;
            }

            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -1.5 * width;
              y = yVerschiebung;
              z = -bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 - hoeheMasskette - abstandVonDerMasslinie;
            } else {
              x = -1.5 * width - hoeheMasskette - abstandVonDerMasslinie;
              y = yVerschiebung;
              z = -bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            }
          }

          break;
        case 'abstandaussen':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).get('hoehe') / 20 + hoeheMasskette - abstandVonDerMasslinie;
          } else {
            x = 0;
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10 + hoeheMasskette + abstandVonDerMasslinie;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
          }

          if (x3d.get('vmittelart') === 4) {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              y = 0;
            } else {
              y = -0.8 - hoeheMasskette - abstandVonDerMasslinie;
            }
          }

          break;
        case 'eindringtiefe':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = -1.5 * width - hoeheMasskette - abstandVonDerMasslinie;
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).get('hoehe') / 40;
          } else {
            x = -1.5 * width;
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10 + hoeheMasskette + abstandVonDerMasslinie;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).get('hoehe') / 40;
          }
          break;
      }

      if (name.substring(0, 8) === 'schraube') {

        var xKopfPos = this.getSchraubeKopfPos(name, 'x');
        var zKopfPos = this.getSchraubeKopfPos(name, 'z');

        if (this.get('typ') === 'xPos') {
          if (x3d.get('istHt')) {
            z = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('z') / 20;
          } else {
            z = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y') / 20;
          }

          if (this.austrittspunktRechts) {
            cords = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 1 + 0.5 * skalierungsfaktor - 0.2 / skalierungsfaktor - 0.25 + ' ' + -xKopfPos / 2 + ' ' + (-z - 0.3);
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = xKopfPos / 2 + ' ' + (0.5 * skalierungsfaktor - 0.3 / skalierungsfaktor - 1) + ' ' + (-z - 0.3);
            } else {
              cords = xKopfPos / 2 + ' 0 ' + (-z - (0.5 * skalierungsfaktor - 0.2 / skalierungsfaktor));
            }
          }
        }

        if (this.get('typ') === 'zPos') {

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') && !x3d.get('istHt')) {
            x = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 0.5;
          } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 0.3;
          } else {
            x = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10;
          }

          var delta = 0;
          var zPos = 0;
          var bezugspunkt = 0;

          switch (this.get('variable')) {
            case 'randAbstand1':
              bezugspunkt = -x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt + x3d.get('randAbstand1') / 20;
              delta = 0.6;
              break;
            case 'randAbstand2':
              bezugspunkt = x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt - x3d.get('randAbstand2') / 20;
              break;
            case 'schraubenAbstand1':
              bezugspunkt = -x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt + x3d.get('schraubenAbstand1') / 20 + x3d.get('randAbstand1') / 10;
              delta = 0.6;
              break;
            case 'schraubenAbstand2':
              bezugspunkt = x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt - x3d.get('schraubenAbstand2') / 20 - x3d.get('randAbstand2') / 10;
              break;
          }

          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            cords = x + ' ' + (0.5 * skalierungsfaktor - 0.3 / skalierungsfaktor + delta - 0.35) + ' ' + zPos;
          } else {
            cords = x + delta + (0.5 * skalierungsfaktor - 0.3 / skalierungsfaktor) + ' ' + y + ' ' + zPos;
          }
        }
      }

      if (name === 'lastenPfeil') {
        y = x3d.get('bauteile').findBy('id', 'lastenPfeil').get('translations').objectAt(0).get('y') - 0.65 - 0.3 * skalierungsfaktor + 0.3 / skalierungsfaktor;
      }

      cords = x + ' ' + y + ' ' + z;
      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.viewpointHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var variable = this.get('variable');
      var values;
      var x = 0,
          y = 0,
          z = 0,
          omega = 0;
      var w90Grad = 90 * Math.PI / 180;

      var item = bauteile.findBy('id', name);

      switch (variable) {
        case 'breite':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = -1;
            z = -1;
            omega = 4.1888;
          } else {
            x = 0;
            y = 1;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'hoehe':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 1;
            z = -1;
            omega = 4.1888;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = w90Grad;
          }
          break;
        case 'abstandobererrand':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 1;
            z = -1;
            omega = 4.1888;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = w90Grad;
          }
          break;
        case 'abstandvmreihen':
          if (x3d.get('vmittelart') === 4) {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = -1;
              z = -1;
              omega = 4.1888;
            } else {
              x = 0;
              y = 1;
              z = 0;
              omega = -w90Grad;
            }
          } else {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 1;
              z = -1;
              omega = 4.1888;
            } else {
              x = 0;
              y = 0;
              z = 1;
              omega = w90Grad;
            }
          }
          break;
        case 'abstandaussen':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 0;
            omega = 0;
          }
          break;
        case 'eindringtiefe':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = -1;
            z = -1;
            omega = 4.1888;
          } else {
            x = 0;
            y = 1;
            z = 0;
            omega = -w90Grad;
          }
          break;
      }

      values = x + ' ' + y + ' ' + z + ' ' + omega;

      if (name.substring(0, 8) === 'schraube') {

        if (this.get('typ') === 'xPos') {
          if (this.austrittspunktRechts) {
            values = '0 0 1 -1.5707';
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              values = '0 0 0 0';
            } else {
              values = '1 0 0 -1.5707';
            }
          }
        }

        if (this.get('typ') === 'zPos') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '0 1 0 -1.5707';
          } else {
            values = '1 1 1 4.2';
          }
        }
      }

      if (name === 'lastenPfeil') {
        values = '0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.viewpointHelper'),

    wert: (function () {

      var name = this.get('name');
      var variable = this.get('variable');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = ' ';

      var item = x3d.get('bauteile').findBy('id', name);

      switch (variable) {
        case 'breite':
          if (x3d.get('htBreiteEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          }
          break;
        case 'hoehe':
          if (x3d.get('htHoeheEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          break;
        case 'abstandobererrand':
          if (x3d.get('abstandobererrandEingetragen')) {
            value = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandobererrand')).toFixed(1);
          }
          break;
        case 'abstandvmreihen':
          if (x3d.get('abstandvmreihenEingetragen')) {
            value = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandvmreihen')).toFixed(1);
          }
          break;
        case 'abstandaussen':
          if (x3d.get('abstandaussenEingetragen')) {
            value = Number(bauteile.findBy('id', 'anschlussparameter').get('anschlussparameter').objectAt(0).get('abstandaussen')).toFixed(1);
          }
          break;
        case 'eindringtiefe':
          if (x3d.get('eindringtiefeEingetragen')) {
            value = (Number(bauteile.findBy('id', 'cylinder').get('cylindersizes').objectAt(0).get('hoehe')) / 2).toFixed(1);
          }
          break;
      }

      if (name.substring(0, 8) === 'schraube' && x3d.get('ergebnisGeladen')) {
        if (this.get('typ') === 'xPos' && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
          var xKopfPos = this.getSchraubeKopfPos(name, 'x');
          value = Math.abs((xKopfPos * 10).toFixed(1));
          value = parseFloat(value).toFixed(1);
        }

        if (this.get('typ') === 'zPos' && !bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
          switch (this.get('variable')) {
            case 'randAbstand1':
              value = x3d.get('randAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'randAbstand2':
              value = x3d.get('randAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand1':
              value = x3d.get('schraubenAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand2':
              value = x3d.get('schraubenAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
          }
        }
      }

      if (name === 'lastenPfeil') {
        value = x3d.get('bemessungslast');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      return value;
    }).property('model.firstObject.transformHelper'),

    diffusecolor: (function () {
      var name = this.get('name');
      var typ = this.get('variable');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('htbreiteAktiv') === true && name === 'traeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name === 'traeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('abstandobererrandAktiv') === true && name === 'traeger' && typ === 'abstandobererrand') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('abstandvmreihenAktiv') === true && name === 'traeger' && typ === 'abstandvmreihen') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('abstandaussenAktiv') === true && name === 'traeger' && typ === 'abstandaussen') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('eindringtiefeAktiv') === true && name === 'traeger' && typ === 'eindringtiefe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('bemessungslastAktiv') === true && name === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.abstandobererrandAktiv', 'model.firstObject.abstandvmreihenAktiv', 'model.firstObject.abstandaussenAktiv', 'model.firstObject.eindringtiefeAktiv', 'model.firstObject.bemessungslastAktiv'),

    textgroesse: (function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    }).property('model.firstObject.skalierungsfaktor', 'model.firstObject.transformHelper'),

    getSchraubeKopfPos: function getSchraubeKopfPos(name, achse) {
      var value = 0;

      var x3d = this.get('model');

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      var alpha = item.get('rotations').objectAt(0).get('z');
      var beta = item.get('rotations').objectAt(0).get('y');

      if (alpha > 1.5707 && !x3d.objectAt(0).get('schraubenGekreuzt')) {
        alpha = 1.5707 - (alpha - 1.5707);
        xVerschiebung = xVerschiebung * -1;
      }

      var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

      // var xKopfPos = -(Math.cos(beta)*yVerschiebung/Math.tan(alpha) - xVerschiebung);

      var xKopfPos;

      if (xVerschiebung > 0) {

        var vwinkel = x3d.objectAt(0).get('vWinkel');
        var xGesamt = yVerschiebung / Math.tan(alpha - 1.5707);
        var x2 = Math.abs(xGesamt) - xVerschiebung / Math.cos(beta);
        var gamma = Math.PI - (alpha - 1.5707) - vwinkel * Math.PI / 180;
        var a = x2 * Math.sin(alpha - 1.5707) / Math.sin(gamma);
        xKopfPos = Math.cos(vwinkel * Math.PI / 180) * a * -1;
        xKopfPos = Math.cos(beta) * xKopfPos;
      } else {
        xKopfPos = -(Math.cos(beta) * yVerschiebung / Math.tan(alpha) - xVerschiebung);
        if (Number(xKopfPos) > Number(x3d.objectAt(0).get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10)) {
          this.austrittspunktRechts = true;
          xKopfPos = (xKopfPos - x3d.objectAt(0).get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10) * Math.sin(alpha);
        }
      }

      var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

      if (achse === 'x') {
        value = xKopfPos;
      } else if (achse === 'z') {
        value = zKopfPos;
      }

      return value;
    }

  });

  exports['default'] = x3dText;

});