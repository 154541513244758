define('m10/models/x3ddefault', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var X3D = DS['default'].Model.extend({
    modul: DS['default'].attr(''),
    transformHelper: DS['default'].attr(''),
    viewpointHelper: DS['default'].attr(''),
    cylindertrigger: DS['default'].attr('boolean', { defaultValue: false }),

    vmittelart: DS['default'].attr(''),

    bauteile: DS['default'].hasMany('bauteil', { async: false }),

    resetFieldOfView: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkel: DS['default'].attr('number', { defaultValue: 90 }),
    vWinkel: DS['default'].attr('number', { defaultValue: 0 }),
    bemessungslast: DS['default'].attr('number', { defaultValue: 0 }),

    lastViewpoint: DS['default'].attr('string', { defaultValue: 'viewpointDefault' }),
    aktuellerSchraubenTyp: DS['default'].attr('string', { defaultValue: 'standardSchraube' }),

    hthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    htHoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    htbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    htBreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    vmdurchmesserAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    vmdurchmesserEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    eindringtiefeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    eindringtiefeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    anzahlvmreihenAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    anzahlvmreihenEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    anzahlvmreihenhorizontalAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    anzahlvmreihenhorizontalEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    abstandvmreihenAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    abstandvmreihenEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    abstandobererrandAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    abstandobererrandEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    abstandaussenAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    abstandaussenEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    abstandvmgruppenAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    abstandvmgruppenEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    bemessungslastAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    bemessungslastEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    schraubenlisteAktualisiert: DS['default'].attr('boolean', { defaultValue: false }),

    vmAktualisiert: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    vWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),

    istHt: DS['default'].attr('boolean', { defaultValue: true }),
    schraubenGekreuzt: DS['default'].attr('boolean', { defaultValue: true }),

    skalierungsfaktor: DS['default'].attr('number', { defaultValue: function defaultValue() {
        return 0.5;
      } }),
    startDistance: DS['default'].attr('number', { defaultValue: 10 }),
    aktuelleDistance: DS['default'].attr('number', { defaultValue: 10 }),
    textRefresh: DS['default'].attr('number', { defaultValue: 0 }),

    randAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    randAbstand2: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstand2: DS['default'].attr('number', { defaultValue: 0 }),

    abstaendeAnzeigen: DS['default'].attr('boolean', { defaultValue: true }),
    ergebnisGeladen: DS['default'].attr('boolean', { defaultValue: false }),

    istGitterModell: DS['default'].attr('boolean', { defaultValue: false })

  });

  exports['default'] = X3D;

});